// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Homepage from './screen/Homepage.js';
import AboutUsPage from './screen/Aboutus.js';
import ContactUsPage from './screen/Contactus.js';
import  PricingPage from './screen/Pricing.js';
import MediaPage from './screen/Media.js';
import Projectlocation from './screen/Projectlocation.js';
import Gallery from './screen/Gallery.js';
import DevelopmentUpdates from './screen/DevelopmentUpdates.js';
import FAQs from './screen/FAQS.js';
import FileVerification from './screen/FileVerification.js';
import NewsEvents from './screen/NewsEvents.js';
import PayOnline from './screen/PayOnline.js';
import NewseventDetail from './screen/NewseventDetail.js';
import AuthorizesSalesPartner from './screen/AuthorizesSalesPartner.js';



function App() {
  return (
    <Router>
      <div className="App">
      
        <Routes>
        <Route path="/" element={<Homepage />} />
          <Route path="/about-us" element={<AboutUsPage />} /> 
          <Route path="/deveolpmentupdates" element={<DevelopmentUpdates />} />
          <Route path="/FAQs" element={<FAQs/>} />
          <Route path="/fileverification" element={<FileVerification/>} />
          <Route path="/newsevent" element={<NewsEvents />} />
          <Route path='/authorizedpartner' element={<AuthorizesSalesPartner/>}/>
          <Route path="/news/:id" element={<NewseventDetail/>}/>
          <Route path="/payonline" element={<PayOnline />} />      
          <Route path="/location" element={<Projectlocation/>}/>         
          <Route path="/contact-us" element={<ContactUsPage />} /> 
          <Route path="/pricing" element={<PricingPage />} /> 
          <Route path="/media" element={<MediaPage />} /> 
          <Route path="/gallery" element={<Gallery />} /> 
        </Routes>
      </div>
    </Router>
  );
}

export default App;
