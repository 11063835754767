import React from 'react';
import './NewsPopup.css';




const NewsPop = ({ onClose }) => {


  return (
    <div className="newspopup-container">
      <button className="newsclose-button" onClick={onClose}>X</button> {/* Close button */}
      <div className="newspopup-content">
        <h1><b>Important Notice!</b></h1>  
        <p className='newsred'>"Kindly note that all payments for D1 Capital Park City must be made directly to the <b>Official Bank Account of D1 Capital Park City</b> or submitted at the designated offices of D1 Capital Park City in <b>Blue Area, Islamabad</b>, or <b>Model Town Corporate Office, Lahore</b>. Ensure you receive an official receipt. Avoid giving payments to individuals or unauthorized entities. D1 Capital Park City holds no responsibility for payments made through unofficial channels." <br/> </p>
        <h4>Offical Bank Account (Meezan Bank)</h4>
        <p>Account Title: Capital Park City (Pvt) Ltd.<br/></p>
         <p> IBAN: PK82 MEZN 0008 2901 0393 6789<br/></p> 
        <p className='Bank-Detail'>Phone Number: +92 51 111 31 00 31<br/></p>
         <p> Account Number: 08290103936789<br/></p>
        <h3>Thank you for your cooperation.</h3>
      </div>
    </div>
  );
};

export default NewsPop;
