import React, {useEffect, useState, lazy, Suspense}from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer';
import Client from '../components/Media/Amenties';
import Whatsapp from '../components/FloatingWhatsapp.js';
import phata from '../assets/phata.gif';
import ReactLoading from '../assets/React-Loading.gif';
import {Helmet} from 'react-helmet-async';

const Gridyoutube = lazy(() => import('../components/Media/Gridyoutube'));


const Media = () => {
  const channelId = 'UCeI49w7XidwBuEl9bDmS-cA';

  const [scrollPosition, setScrollPosition] = useState(0);

  // Function to handle scroll events and update the scroll position
  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Clean up the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    document.title = 'D1 Capital Park City | Media';
  },[]);

  return (
      <div>
        <Helmet>
        <title>D1 Capital Park City | Media</title>
        <meta name="description" content="D1 Capital Park City Amenties and Youtube Updates."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/media'/>
      </Helmet>
   <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
         <div className="Header"><Header />
         <div className="phata">
          <img src={phata} alt="Phata" />
        </div></div>



        <div>
        <h1 className='h1stake'>YOUTUBE</h1>
          <Gridyoutube channelId={channelId}/>
        </div>

        {scrollPosition > 100 && (
        <div className='client'>
        <h1 className='h1stake'>PROJECT AMENITIES</h1>
        <Client/>
        </div>
        )}
 <Whatsapp/>
  <br/>
   <div> 
              <Footer/>
        </div>
        </Suspense>
      </div>
    )
}

export default Media
