import React from 'react';
import './Popup.css';
import Button from '../../utils/Button';
import { Link } from 'react-router-dom';



const Popup = ({ onClose }) => {

  return (
    <div className="popup-container">
      <button className="close-button" onClick={onClose}>X</button> {/* Close button */}
      <div className="popup-content">
        <h1>Welcome to D1 Capital Park City</h1>
        <h3>Book Your Interest Now! </h3>
        
    <p className='red'><b>"2KM" Away From Islamabad International Airport!</b> </p>
    <Link to="/contact-us">
<button className='btn41-43 btn-43' >Submit Your Interest</button></Link>
       
      </div>
    </div>
  );
};

export default Popup;
