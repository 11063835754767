import React from 'react'
import '../styles/FloatingWhatsapp.css'
import Whatsapp from '../assets/whatsapp.png'

const FloatingWhatsapp = () => {
    const openWhatsApp = () => {
        // Replace the placeholder number with the actual WhatsApp number
        const phoneNumber = '+923341111771';
      
        // Open WhatsApp with the provided number
        window.open(`https://wa.me/${phoneNumber}`, '_blank');
      };
    
      return (
        <div className="whatsapp-icon" onClick={openWhatsApp}>
          <img src={Whatsapp} alt="WhatsApp Icon" />
        </div>
      );
    };
    

export default FloatingWhatsapp
