import React, { Suspense, useEffect, lazy } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Whatsapp from '../components/FloatingWhatsapp.js';
import phata from '../assets/phata.gif';
import PaymentPlan from '../utils/PaymentPlanButton.js'; 
import ReactLoader from '../assets/React-Loading.gif';
import '../styles/Pricing.css';
import {Helmet} from 'react-helmet-async';

// Lazy load PricingCard component
const LazyPricingCard = lazy(() => import('../components/Pricing/PricingCard'));

const Pricing = () => {

  useEffect(() => {
    document.title = 'D1 Capital Park City | Pricing Plan';
  },[]);

  return (
    <div>
      <Helmet>
        <title>D1 Capital Park City | Pricing Plan</title>
        <meta name="description" content="D1 Capital Park City have the Most Affordable Pricing Plan."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/pricing'/>
      </Helmet>
      <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoader} alt="Loading..." /></div>}>
      <Header />
      <div className="phata">
        <img src={phata} alt="Phata" />
      </div>

      <h1 className='h1stake'>MOST AFFORDABLE PRICES</h1>
      <div className='Plan'>
  
        {/* <PaymentPlan />*/}
      </div>

      {/* Wrap lazy-loaded component with Suspense */}
        <div>
          {/* Render lazy-loaded component */}
          <LazyPricingCard />
        </div>


      <Whatsapp />
      <Footer />
      </Suspense>
    </div>
  );
}

export default Pricing;
