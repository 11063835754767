import React, { lazy, Suspense, useEffect } from 'react';
import Header from '../components/Header';
import phata from '../assets/phata.gif';
import ReactLoading from '../assets/React-Loading.gif';
import FloatingWhatsapp from '../components/FloatingWhatsapp';
import {Helmet} from 'react-helmet-async';

const OurTeam = lazy(() => import('../components/Aboutus/OurTeam.js'));
const Message = lazy(() => import('../components/Aboutus/Message.js'));
const Footer = lazy(() => import('../components/Footer'));

const Aboutus = () => {

  useEffect(() => {
    document.title = 'D1 Capital Park City | About Us';
  },[]);

  return (
    <div>
      <Helmet>
        <title>D1 Capital Park City | About Us</title>
        <meta name="description" content="D1 Capital Park City Group and Team."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/about-us'/>
      </Helmet>
      <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
        <div className="Header">
          <Header />
          <div className="phata">
            <img src={phata} alt="Phata" loading="lazy" />
          </div>
        </div>

        <div className='message'>
          <h1 className='h1stake'>ABOUT OUR GROUP</h1>
          <Message />
        </div>


     
        <div className='team'>
        <h1 className='h1stake'>MEET OUR TEAM</h1>
        <OurTeam/>
        </div>
        <FloatingWhatsapp />


        <br />

        <div>
          <Footer />
        </div>
      </Suspense>
    </div>
  );
};

export default Aboutus;
