import React from 'react';
import '../../styles/OurLocation.css';
import wonderlocation from '../../assets/Wonderlocationimg.png';

const OurLocation = () => {

  return (
    <div className='ourlocation-container'>
      <div>
        <p className='location-para'>
          Just 2 Km away from Islamabad International Airport and only 10 minutes drive away from the CPEC route and just a 10 minutes driving distance from M2 Thalian Interchange in a lush green and scenic area.
        </p>
        <img className="wonderlocation"src={wonderlocation} alt="location" />
      </div>
      
    </div>
  );
};

export default OurLocation;