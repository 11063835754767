import React from 'react';
import '../../styles/Amenties.css';
import commas from '../../assets/Commas.png';
import { IKImage } from 'imagekitio-react';

const ImageKitConfig = {
  publicKey: 'public_ai3DHWL78cix/8pydFCCczQwpqY=',
  urlEndpoint: 'https://ik.imagekit.io/HamzaMalik',
};

const Client = () => {
  return (

    <div className='client-container'>
     
      <div className='slider-wrapper'>
        <div className="card">
        <IKImage
        className="card-img-top"
    path="/GARDEN.png?updatedAt=1708787555541" 
    publicKey= {ImageKitConfig.publicKey}
    urlEndpoint= {ImageKitConfig.urlEndpoint}
/>
          <div className="card-body">
            <h4>Garden By The Bay</h4>
            <p className="card-text">D1 Capital Park City has introduced new concept of Garden by the bay. The Garden by the bay is inspired by the Singapore.</p>
            <img className="card-img-bottom" src={commas} alt="Card  design" loading="lazy" />
          </div>
        </div>

        <div className="card">
        <IKImage
        className="card-img-top"
    path="/rainforest.png?updatedAt=1708787557374" 
    publicKey= {ImageKitConfig.publicKey}
    urlEndpoint= {ImageKitConfig.urlEndpoint}
/>
          <div className="card-body">
          <h4>Artificial Rain Forest</h4>  
            <p className="card-text">D1 Capital Park City has introduced another new concept of Artificial Rain Forest "The City of Tourism"</p>
            <img className="card-img-bottom" src={commas} alt="Card  design"  loading="lazy"/>
          </div>
        </div>


        <div className="card">
        <IKImage
        className="card-img-top"
    path="/lighthouse.png?updatedAt=1708787556318" 
    publicKey= {ImageKitConfig.publicKey}
    urlEndpoint= {ImageKitConfig.urlEndpoint}
/>      
    <div className="card-body">
          <h4>Light House</h4>  
            <p className="card-text">D1 Capital Park City has the beautiful park views and light house. The Light House is just amazing.</p>
            <img className="card-img-bottom" src={commas} alt="Card design"  loading="lazy"/>
          </div>
        </div>


        <div className="card">
        <IKImage
        className="card-img-top"
    path="/lake.png?updatedAt=1708787557273" 
    publicKey= {ImageKitConfig.publicKey}
    urlEndpoint= {ImageKitConfig.urlEndpoint}
/>    
          <div className="card-body">
            <h4>Lake View</h4>
            <p className="card-text">D1 Capital Park City has the beautiful lake view. The Beautiful Lake View attracts tourists to "D1"</p>
            <img className="card-img-bottom" src={commas} alt="Card design"  loading="lazy"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Client;