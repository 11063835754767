import React, { useState, useEffect, Suspense } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Whatsapp from '../components/FloatingWhatsapp.js';
import phata from '../assets/phata.gif';
import '../styles/NewsEvent.css';
import ReactLoading from '../assets/React-Loading.gif';
import {Helmet} from 'react-helmet-async';
import NewsPop from '../components/NewsEvents/NewsPop.js';
import newsEvent from '../JsonDoc/newsEvents.json';

const NewsEvents = () => {
  const [newsEvents, setNewsEvents] = useState([]);
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    document.title = 'D1 Capital Park City | News/Events';
  },[]);



  useEffect(() => {
    const fetchNewsEvents = async () => {
      try {
        setNewsEvents(newsEvent);
      } catch (error) {
        console.error('Error fetching news events:', error);
      }
    };

    fetchNewsEvents();
  }, []);




  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div>
      <Helmet>
        <title>D1 Capital Park City | News/Events</title>
        <meta name="description" content="D1 Capital Park City Latest News and Events Updates."/>
        <link rel='canonical' href='https://d1capitalparkcity.com/newsevent'/>
      </Helmet>
      <Suspense fallback={<div style={{ backgroundColor:"#000", width:"100%", height:"100vh" }}><img style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', borderRadius: '8px' }} src={ReactLoading} alt="Loading..." /></div>}>
      <Header />
      <div className="phata">
        <img src={phata} alt="Phata" />
      </div>

      <div>
        <h1 className="h1stake">LATEST NEWS/EVENTS</h1>
      </div>

      <div className="headline-container">
        <div className="right-to-left-headline">
          <span className="development-red-text">LATEST NEWS: </span>
          <span className="development-text">
            D1 Capital Park City is going to launch soon.....
          </span>
        </div>
      </div>
      <br />

      <div className="news-events-grid">
        {newsEvents.map(event => (
          <div key={event.id} className="news-event-card">
            <img src={event.imageSrc} alt={event.heading} className="news-event-image" loading='lazy' />
            <h2 className="news-event-heading">{event.heading}</h2>
            <Link to={`/news/${event.id}`}>
              <button className="btn42-43 btn-42">Read Article</button>
            </Link>
          </div>
        ))}
      </div>
      {showPopup && <NewsPop onClose={handleClosePopup} />}
        <Whatsapp/>
      <Footer />
      </Suspense>
    </div>
  );
};

export default NewsEvents;
